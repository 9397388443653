import { configureStore } from "@reduxjs/toolkit";

import dashboardReducer from "../views/Dashboard/dashboardSlice";
import searchReducer from "../views/SearchBox/searchSlice";

export default configureStore({
  reducer: {
    dashboard: dashboardReducer,
    search: searchReducer
  },
});
