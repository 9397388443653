import { Button } from "antd";
import css from "./MainButton.module.css";
import classNames from "classnames";
export const MainButton = ({ children, size, rootClassName, ...props }) => {
  return (
    <Button
      {...props}
      type="primary"
      size={size}
      className={classNames(css.primaryButton, rootClassName)}
    >
      {children}
    </Button>
  );
};

export const MainButtonDark = ({ children, size, rootClassName, ...props }) => {
  return (
    <Button
      {...props}
      type="primary"
      size={size}
      className={classNames(css.primaryButtonDark, rootClassName)}
    >
      {children}
    </Button>
  );
};
