import React from 'react';
import { Breadcrumb } from 'antd';
import css from './Breadcrumbs.module.css';

export const Breadcrumbs = () => (
  <Breadcrumb
		className={css.breadcrumbs}
	routes={[
	  {
		path: '/stocks',
		breadcrumbName: 'Stocks',
	  },
	  {
		path: '/stocks_sales',
		breadcrumbName: 'Stocks and Sales',
		},
		{
				path: '/dashboard',
				breadcrumbName: 'Dashboard',
		}
	]}
  />
);