import axios from "axios";
import { buildQueryString, authHeader } from "../utils/helpers";
const { REACT_APP_SERVER_URL, REACT_APP_ORTHUS_URL, REACT_APP_API_BASIC_AUTH_USERNAME, REACT_APP_API_BASIC_AUTH_PASSWORD } = process.env;

//axios.defaults.auth = {username: REACT_APP_API_BASIC_AUTH_USERNAME, password: REACT_APP_API_BASIC_AUTH_PASSWORD};
//axios.defaults.withCredentials = true;
axios.defaults.crossDomain = true;

export const loginRequest = (email, password) => {
  return axios.post(`${REACT_APP_ORTHUS_URL}/v1/users/sign_in`,{ email, password });
}

export const getOverviewRequest = ({ page, search, sort, type }) => {
  return axios.get(
    `${REACT_APP_SERVER_URL}/api/overview?search=${search}&page=${page}&sort=${sort}`,
    { headers: authHeader() }
  );
};

export const getSalesSummaryRequest = ({ upc, type, page, filters, sort }) => {
  return axios.get(
    `${REACT_APP_SERVER_URL}/api/sales/${upc}/${type}?filter=${filters}&page=${page}&sort=${sort}`,
    { headers: authHeader() }
  );
};

export const getGoodsRequest = ({ upc, page, filters, sort }) => {
  return axios.get(
    `${REACT_APP_SERVER_URL}/api/goods/${upc}?filter=${filters}&page=${page}&sort=${sort}`,
    { headers: authHeader() }
  );
};

export const getCurrentOrdersRequest = ({ upc, page, filters, sort }) => {
  return axios.get(
    `${REACT_APP_SERVER_URL}/api/orders/${upc}?filter=${filters}&page=${page}&sort=${sort}`,
    { headers: authHeader() }
  );
};

export const getSearchRequest = ({ query }) => {
  return axios.get(
    `${REACT_APP_SERVER_URL}/api/search/${query}`,
    { headers: authHeader() }
  );
};