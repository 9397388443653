import { PrivateRoute } from "./components/PrivateRoute/PrivateRoute";

import { NotFound } from "./views/NotFound/NotFound";
import { Dashboard } from "./views/Dashboard/Dashboard";
import { Login } from "./views/Login/Login";

const routes = [
  {
    path: "/dashboard",
    element: <PrivateRoute element={<Dashboard />} />,
    login: true,
    // Add additional configuration options as needed
  },
  {
    path: "/login",
    element: <Login />,
    login: true,
    // Add additional configuration options as needed
  },
  {
    path: "*",
    element: <NotFound />,
    // Add additional configuration options as needed
  },
];

export default routes;
