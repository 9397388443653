import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../../store/AuthContext";

export const PrivateRoute = ({ element }) => {
  const { user, isAuthenticated } = useAuth();

  return user || isAuthenticated ? element : <Navigate to="/login" />;
};

