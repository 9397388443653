import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../store/AuthContext";

import { Col, Flex, Form, Input, Checkbox, Button } from "antd";
import { PageHeader } from "../../components/PageHeader/PageHeader";
import { Notification } from "../../components/Notification/Notification";

import css from "../Dashboard/Dashboard.module.css";

export const Login = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  
  const { login, user, error, loading } = useAuth(); 
  const [showNotification, setShowNotification] = useState(false);
  
  useEffect(() => {
  	if (user) {
		navigate("/");
  	}
  }, [user]);
  
  useEffect(() => {
	  if(error)
	  	setShowNotification(error);
  }, [error]);
  
  const onFinish = (values) => {
  	login(values.email, values.password);	
  };
  
  const onFinishFailed = (errorInfo) => {
  	//console.log('Failed:', errorInfo);
  };
  
  return (
	<Flex className={css.root} vertical>
	<Notification
		mode="error"
		message="Login Error"
		description={error}
		showNotification={showNotification}
		cancel={() => setShowNotification(false)}
	/>

	<Form
		name="basic"
		labelCol={{
		  span: 8,
		}}
		wrapperCol={{
		  span: 16,
		}}
		style={{
		  maxWidth: 600,
		}}
		initialValues={{
		  remember: true,
		}}
		onFinish={onFinish}
		onFinishFailed={onFinishFailed}
		autoComplete="off"
	  >
		<Form.Item
		  label="Email"
		  name="email"
		  rules={[
			{
			  required: true,
			  message: 'Email is required',
			},
		  ]}
		>
		  <Input />
		</Form.Item>
	
		<Form.Item
		  label="Password"
		  name="password"
		  rules={[
			{
			  required: true,
			  message: 'Password is required',
			},
		  ]}
		>
		  <Input.Password />
		</Form.Item>
	
		<Form.Item
		  name="remember"
		  valuePropName="checked"
		  wrapperCol={{
			offset: 8,
			span: 16,
		  }}
		>
		  <Checkbox>Remember me</Checkbox>
		</Form.Item>
	
		<Form.Item
		  wrapperCol={{
			offset: 8,
			span: 16,
		  }}
		>
		  <Button type="primary" htmlType="submit">
			Submit
		  </Button>
		</Form.Item>
	  </Form>
	</Flex>
  );
};
