export const IconSearch = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.5 15L11.5 11M7.5 13C4.18629 13 1.5 10.3137 1.5 7C1.5 3.68629 4.18629 1 7.5 1C10.8137 1 13.5 3.68629 13.5 7C13.5 10.3137 10.8137 13 7.5 13Z"
        stroke="#615E69"
        stroke-width="1.5"
      />
    </svg>
  );
};
