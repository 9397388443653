// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../shared/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../shared/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Footer_footer__kXlYa {
  text-align: center;
  color: #171717;
  background-color: #ffffff;
  border-top: 1px solid #73737333;
  min-height: 40px;
  padding: 10px 20px;

  @media (min-width: 768px) {
    padding: 10px 40px;
  }
}

.Footer_rowLeft__iiS9k {
  width: 50%;
  text-align: left;
}

.Footer_rowRight__BbuxN {
  width: 50%;
  justify-content: flex-start;
  text-align: left;

  @media (min-width: 768px) {
    justify-content: flex-end;
  }
}

.Footer_col__uMvMM {
  cursor: pointer;
  margin-bottom: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,cAAc;EACd,yBAAyB;EACzB,+BAA+B;EAC/B,gBAAgB;EAChB,kBAAkB;;EAElB;IACE,kBAAkB;EACpB;AACF;;AAEA;EACE,UAAU;EACV,gBAAgB;AAClB;;AAEA;EACE,UAAU;EACV,2BAA2B;EAC3B,gBAAgB;;EAEhB;IACE,yBAAyB;EAC3B;AACF;;AAEA;EACE,eAAe;EACf,kBAAkB;AACpB","sourcesContent":[".footer {\n  text-align: center;\n  color: #171717;\n  background-color: #ffffff;\n  border-top: 1px solid #73737333;\n  min-height: 40px;\n  padding: 10px 20px;\n\n  @media (min-width: 768px) {\n    padding: 10px 40px;\n  }\n}\n\n.rowLeft {\n  width: 50%;\n  text-align: left;\n}\n\n.rowRight {\n  width: 50%;\n  justify-content: flex-start;\n  text-align: left;\n\n  @media (min-width: 768px) {\n    justify-content: flex-end;\n  }\n}\n\n.col {\n  cursor: pointer;\n  margin-bottom: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `Footer_footer__kXlYa`,
	"rowLeft": `Footer_rowLeft__iiS9k`,
	"rowRight": `Footer_rowRight__BbuxN`,
	"col": `Footer_col__uMvMM`
};
export default ___CSS_LOADER_EXPORT___;
