import React, {useEffect, useMemo} from 'react';
import { Button, Divider, notification, Space } from 'antd';
const Context = React.createContext({
  name: 'Default',
});
export const Notification = ({ children, description, message, mode = 'success', showNotification, cancel }) => {
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (placement) => {
    api[mode]({
      message,
      description: <Context.Consumer>{({ name }) => description}</Context.Consumer>,
      placement,
    });
  };
  const contextValue = useMemo(
    () => ({
      name: 'Ant Design',
    }),
    [],
  );

  useEffect(() => {
    if (showNotification) {
      openNotification('topRight')
    }
    cancel();
  }, [showNotification]);

  return (
    <Context.Provider value={contextValue}>
      {contextHolder}
    </Context.Provider>
  );
};
