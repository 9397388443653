import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Col, Flex, Form, Input, Row, Table } from "antd";
import { PageHeader } from "../../components/PageHeader/PageHeader";
import { getOverview } from "./dashboardSlice";
import { buildQueryString, debounce } from "../../utils/helpers";
import { HeaderCell } from "./HeaderCell";
import { IconDownload } from "../../components/IconDownload/IconDownload";
import { IconSearch } from "../../components/IconSearch/IconSearch";
import { MainButton } from "../../components/MainButton/MainButton";
import { Notification } from "../../components/Notification/Notification";
import { SalesSummary } from "../SalesSummary/SalesSummary";
import { Goods } from "../GoodsIn/GoodsIn";
import { SearchBox } from "../SearchBox/SearchBox";
import { SectionHeader } from "../../components/SectionHeader/SectionHeader";
import { CurrentOrders } from "../CurrentOrders/CurrentOrders";
import { SalesOrders } from "../SalesOrders/SalesOrders";
import { CSVLink } from "react-csv";

import css from "./Dashboard.module.css";

export const Dashboard = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [upc, setUpc] = useState('');
  const [search, setSearch] = useState({search: [], format: [], range: {start: null, end: null}});
  const [sortDirection, setSortDirection] = useState("");
  const [showNotification, setShowNotification] = useState(false);

  const page = new URLSearchParams(location.search).get("page") || 1;
  //const search = new URLSearchParams(location.search).get("search");
  const sort = new URLSearchParams(location.search).get("sort");

  const onQueryParamsChange = (values) => {
    const queryParams = {
      page,
      search,
      sort,
      ...values,
    };

    const queryString = buildQueryString("/dashboard", queryParams);
    navigate(queryString);
  };

  const { overview, getOverviewInProgress } =
    useSelector((state) => state?.dashboard) || {};

  const { data: rows, totalPages } = overview || {};

  useEffect(() => {
    setUpc('');
    
    dispatch(
      getOverview({
        page,
        search,
        sort: sortDirection,
      }),
    );
  }, [page, search, sortDirection]);

  useEffect(() => {
    if (
      location.state &&
      ["/channelEdited", "/channelCreated"].includes(location.state.from)
    ) {
      setShowNotification(true);
      navigate("/channels", { state: null });
    } else {
      setShowNotification(false);
    }
  }, [location?.state]);

  const columns = [
    {
      title: "Artist",
      editable: true,
      dataIndex: "Artist",
      width: 250,
      sorter: (_, __, sort) =>
        setSortDirection(`Artist:${sort === "ascend" ? "asc" : "desc"}`),
      align: "left",
    },
    {
      title: "Title",
      editable: true,
      dataIndex: "Title",
      width: 250,
      sorter: (_, __, sort) =>
        setSortDirection(`Title:${sort === "ascend" ? "asc" : "desc"}`),
      align: "left",
    },
    {
      title: "Catalogue Number",
      editable: true,
      dataIndex: "Catalogue_Number",
      sorter: (_, __, sort) =>
        setSortDirection(`Catalogue_Number:${sort === "ascend" ? "asc" : "desc"}`),
      width: 100,
      align: "left",
    },
    {
      title: "Format",
      editable: true,
      dataIndex: "Format",
      sorter: (_, __, sort) =>
        setSortDirection(`Format:${sort === "ascend" ? "asc" : "desc"}`),
      width: 150,
      align: "left",
    },
    {
      title: "UPC",
      editable: true,
      dataIndex: "UPC",
      sorter: (_, __, sort) =>
        setSortDirection(`UPC:${sort === "ascend" ? "asc" : "desc"}`),
      width: 200,
      align: "left",
    },
    {
      title: "Release Date",
      editable: true,
      dataIndex: "Release_Date",
      sorter: (_, __, sort) =>
        setSortDirection(`Release_Date:${sort === "ascend" ? "asc" : "desc"}`),
      width: 150,
      align: "left",
      render: (record) => {
        return (
              <span>{new Date(record).toLocaleDateString()}</span>
        );
      }
    },    
    {
      title: "UK PPD",
      editable: true,
      dataIndex: "UK_PPD",
      sorter: (_, __, sort) =>
        setSortDirection(`UK_PPD:${sort === "ascend" ? "asc" : "desc"}`),
      width: 150,
      align: "left",
    },
    {
      title: "Av. Stock",
      editable: true,
      dataIndex: "Available_Stock",
      sorter: (_, __, sort) =>
        setSortDirection(`Available_Stock:${sort === "ascend" ? "asc" : "desc"}`),
      width: 150,
      align: "left",
    },
    {
      title: "Unav. Stock",
      editable: true,
      dataIndex: "Unavailable_Stock",
      sorter: (_, __, sort) =>
        setSortDirection(`Unavailable_Stock:${sort === "ascend" ? "asc" : "desc"}`),
      width: 150,
      align: "left",
    },
    {
      title: "Total Stock",
      editable: true,
      dataIndex: "Total_Warehouse_Stock",
      sorter: (_, __, sort) =>
        setSortDirection(`Total_Warehouse_Stock:${sort === "ascend" ? "asc" : "desc"}`),
      width: 150,
      align: "left",
    },
    {
      title: "Cons. Stock",
      editable: true,
      dataIndex: "Consignment_Stock",
      sorter: (_, __, sort) =>
        setSortDirection(`Consignment_Stock:${sort === "ascend" ? "asc" : "desc"}`),
      width: 150,
      align: "left",
    },    
    {
      title: "Cons. Ship",
      editable: true,
      dataIndex: "Consignment_Ship",
      sorter: (_, __, sort) =>
        setSortDirection(`Consignment_Ship:${sort === "ascend" ? "asc" : "desc"}`),
      width: 150,
      align: "left",
    },    
    {
      title: "Sold Units",
      editable: true,
      dataIndex: "Sold_Units",
      sorter: (_, __, sort) =>
        setSortDirection(`Sold_Units:${sort === "ascend" ? "asc" : "desc"}`),
      width: 150,
      align: "left",
    },    
    {
      title: "FOC Ship",
      editable: true,
      dataIndex: "FOC_Ship",
      sorter: (_, __, sort) =>
        setSortDirection(`FOC_Ship:${sort === "ascend" ? "asc" : "desc"}`),
      width: 150,
      align: "left",
    },   
    {
      title: "Orders",
      editable: true,
      dataIndex: "Orders",
      sorter: (_, __, sort) =>
        setSortDirection(`Orders:${sort === "ascend" ? "asc" : "desc"}`),
      width: 150,
      align: "left",
    },
    {
      title: "Pre Orders",
      editable: true,
      dataIndex: "Pre_Orders",
      sorter: (_, __, sort) =>
        setSortDirection(`Orders:${sort === "ascend" ? "asc" : "desc"}`),
      width: 150,
      align: "left",
    },
    {
      title: "Goods In",
      editable: true,
      dataIndex: "Goods_In",
      sorter: (_, __, sort) =>
        setSortDirection(`Goods_In:${sort === "ascend" ? "asc" : "desc"}`),
      width: 150,
      align: "left",
    },


 

    /*
    {
      title: "Actions",
      dataIndex: "",
      fixed: "right",
      key: "x",
      width: 100,
      align: "left",
      render: (record) => {
        return (
          <Flex gap={16} align={"center"}>
            <div className={css.actionContainer}>
              <span
                className={css.actionIcon}
                onClick={() => {
                  navigate(`/channels/edit/${record.id}`);
                }}
              >
              </span>
            </div>
          </Flex>
        );
      },
    },*/
  ];

  const onSearch = (value) => {
    onQueryParamsChange({ search: value, page: 1 });
  };

  const handleInputChange = (event) => {
    const { value } = event.target;
    debouncedSave(value);
  };
  
  const onRow = (record, rowIndex) => {
    return {
      onClick: (event) => {
        setUpc(record.UPC);  
      }
    };
  };
  
  const onApplySearch = (params) => {
    /*  
    if(Array.isArray(params.search))
      params.search = params.search.map(value=>value.value).join(';');
    
    if(Array.isArray(params.format))
      params.format = params.format.join(';');
    */
    setSearch(params)
  }
  


  const saveInput = (value) => onSearch(value);

  const debouncedSave = debounce(saveInput, 500);

  return (<>
    {/* <PageHeader title="Stock and Sales Dashboard" /> */}
    <PageHeader title="Stock and Sales Dashboard">
      <CSVLink data={rows || []} filename="overview.csv">
        <IconDownload />
      </CSVLink>

    </PageHeader>
    <Flex className={css.root} vertical>

      {/*
      <Form
        name="searchForm"
        style={{ marginTop: 10, marginBottom: 0 }}
        initialValues={{ remember: true }}
        autoComplete="off"
      >
        <Row gutter={16} className={css.searchFormContainer}>
          <Col md={24} lg={18} xl={16}>
            <Form.Item style={{ marginBottom: 10, maxWidth: 266 }} name="name">
              <Input
                defaultValue={search}
                size="large"
                placeholder="Search..."
                style={{ color: "#737373" }}
                prefix={<IconSearch />}
                onChange={handleInputChange}
              />
            </Form.Item>
          </Col>
          <Col md={24} lg={6} xl={8} className={css.actionsWrapper}>
          </Col>
        </Row>
      </Form>
      */}
      <div className={"ibox-holder"}>
        <SearchBox onApply={onApplySearch} />
      </div>
      
      <div className={"ibox-holder"}>
        <SectionHeader title="Search Table">
          <CSVLink data={rows || []} filename="overview.csv">
            <IconDownload />
          </CSVLink>
        </SectionHeader>
        <Form name="tableForm" form={form} component={false}>
          <Table
            components={{
              header: {
                row: HeaderCell,
              },
            }}
            columns={columns}
            rowKey={(record) => record.id}
            onRow={onRow}
            dataSource={rows}
            pagination={{
              position: ["bottomRight"],
              responsive: true,
              onChange: (page) => onQueryParamsChange({ page }),
              current: page,
              total: totalPages,
              pageSize: 10,
              hideOnSinglePage: true,
              showSizeChanger: false,
            }}
            loading={getOverviewInProgress}
            style={{ marginTop: 10 }}
            scroll={{ x: "100%" }}
          />
        </Form>
      </div>
      {upc && <div className={"ibox-holder"}><SalesSummary upc={upc} /></div>}
      {upc && <div className={"ibox-holder"}><SalesOrders upc={upc} /></div>}
    </Flex>
  </>
  );
};
