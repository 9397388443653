import React, { useState } from 'react';
import { Menu } from 'antd';
import { Link, useLocation } from "react-router-dom";
import { AppstoreOutlined, MailOutlined } from '@ant-design/icons';
import css from './MainMenu.module.css';

export const MainMenu = () => {
	const items = [
	  {
		key: 'dashboard_container',
		icon: <MailOutlined />,
		label: 'Dashboard',
		children: [
		  {
			key: '1-1',
			label: 'Item 1',
			type: 'group',
			children: [
			  {
				key: '1',
				label: 'Option 1',
			  },
			  {
				key: '2',
				label: 'Option 2',
			  },
			],
		  },
		  {
			key: '1-2',
			label: 'Item 2',
			type: 'group',
			children: [
			  {
				key: '3',
				label: 'Option 3',
			  },
			  {
				key: '4',
				label: 'Option 4',
			  },
			],
		  },
		],
	  },
	  {
		key: 'stock',
		icon: <AppstoreOutlined />,
		label: 'Stock',
		children: [
		  {
			key: 'stock_sales',
			label: 'Stock and Sales',
			children: [
			  {
				key: 'dashboard',
				label: <Link to='/dashboard'>Dashboard</Link>
			  }
			],
		  },
		],
	  }
	];
	
	const [current, setCurrent] = useState('dashboard');
	
	const onClick = (e) => {
  		console.log('click', e);
		setCurrent(e.key);
	};

  return (
	  
  	<Menu
	  onClick={onClick}
	  className={css.mainmenu}
	  mode="inline"
	  selectedKeys={[current]}
	  defaultOpenKeys={['stock']}
	  items={items}
	/>	
  )
}