import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Col, Flex, Form, Input, Row, Table } from "antd";
import { PageHeader } from "../../components/PageHeader/PageHeader";
import { getCurrentOrders } from "../Dashboard/dashboardSlice";
import { buildQueryString, debounce } from "../../utils/helpers";
import { HeaderCell } from "../Dashboard/HeaderCell";
import { IconDownload } from "../../components/IconDownload/IconDownload";
import { IconSearch } from "../../components/IconSearch/IconSearch";
import { MainButton } from "../../components/MainButton/MainButton";
import { Notification } from "../../components/Notification/Notification";
import { dateCell } from "../../components/DateCell/DateCell";
import { CSVLink } from "react-csv";

import css from "../Dashboard/Dashboard.module.css";

export const CurrentOrders = ({upc}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  
  const [type, setType] = useState("type");
  const [sortDirection, setSortDirection] = useState("");
  const [showNotification, setShowNotification] = useState(false);

  const page = new URLSearchParams(location.search).get("page") || 1;
  const search = new URLSearchParams(location.search).get("search");
  const sort = new URLSearchParams(location.search).get("sort");

  const onQueryParamsChange = (values) => {
	const queryParams = {
	  upc,	
	  type,	
	  page,
	  search,
	  sort,
	  ...values,
	};
	//SetState?
	//const queryString = buildQueryString("/overview", queryParams);
	//navigate(queryString);
  };

  const { currentOrders, getCurrentOrdersInProgress } =
	useSelector((state) => state?.dashboard) || {};

  const { data: rows, totalPages } = currentOrders || {};

  useEffect(() => {
	dispatch(
	  getCurrentOrders({
		upc,
		type,  
		page,
		search,
		sort: sortDirection,
	  }),
	);
  }, [upc, type, page, search, sortDirection]);

  const columns = [
	{
	  title: "UPC",
	  editable: true,
	  dataIndex: "UPC",
	  width: 250,
	  sorter: (_, __, sort) =>
		setSortDirection(`UPC:${sort === "ascend" ? "asc" : "desc"}`),
	  align: "left",
	},
	{
	  title: "Account Name",
	  editable: true,
	  dataIndex: "Account_Name",
	  width: 250,
	  sorter: (_, __, sort) =>
		setSortDirection(`Account_Name:${sort === "ascend" ? "asc" : "desc"}`),
	  align: "left",
	},
	{
	  title: "Account Type",
	  editable: true,
	  dataIndex: "Account_Type",
	  width: 250,
	  sorter: (_, __, sort) =>
		setSortDirection(`Account_Type:${sort === "ascend" ? "asc" : "desc"}`),
	  align: "left",
	},
	{
	  title: "Order Date",
	  editable: true,
	  dataIndex: "Order_Date",
	  width: 250,
	  sorter: (_, __, sort) =>
		setSortDirection(`Order_Date:${sort === "ascend" ? "asc" : "desc"}`),
	  align: "left",
	  render: (record) => dateCell(record)
	},
	{
	  title: "Sold Units",
	  editable: true,
	  dataIndex: "Sold_Units",
	  width: 100,
	  sorter: (_, __, sort) =>
		setSortDirection(`Sold_Units:${sort === "ascend" ? "asc" : "desc"}`),
	  align: "left",
	},
	{
	  title: "FOC Cons.",
	  editable: true,
	  dataIndex: "FOC_Consignment",
	  width: 100,
	  sorter: (_, __, sort) =>
		setSortDirection(`FOC_Consignment:${sort === "ascend" ? "asc" : "desc"}`),
	  align: "left",
	},
	{
	  title: "Returns",
	  editable: true,
	  dataIndex: "Returns",
	  width: 100,
	  sorter: (_, __, sort) =>
		setSortDirection(`Returns:${sort === "ascend" ? "asc" : "desc"}`),
	  align: "left",
	},	
  ];

  return (
	<Flex className={css.root} vertical>
	<PageHeader title="Current Orders">
		<CSVLink data={rows || []} filename="current_orders.csv">
			<IconDownload />
	  	</CSVLink>

	</PageHeader>
	<Table
	  components={{
		header: {
		  row: HeaderCell,
		},
	  }}
	  columns={columns}
	  rowKey={(record) => record.id}
	  dataSource={rows}
	  pagination={{
		position: ["bottomRight"],
		responsive: true,
		onChange: (page) => onQueryParamsChange({ page }),
		current: page,
		total: totalPages * 10,
		pageSize: 10,
		hideOnSinglePage: true,
		showSizeChanger: false,
	  }}
	  loading={getCurrentOrdersInProgress}
	  style={{ marginTop: 10 }}
	  scroll={{ x: "100%" }}
	/>
	</Flex>
  );
};
