import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getSearchRequest
} from "../../api";

const initialState = {
	results: [],
	getSearchInProgress: false,
	getSearchError: false
};

export const getSearch = createAsyncThunk(
  "search/getSearch",
  async (_, { rejectWithValue }) => {
	try {
	  const { query } = _;
	  const response = await getSearchRequest({
		query
	  });
	  return response.data;
	} catch (error) {
	  return rejectWithValue(error.message);
	}
  },
);

export const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
	clearData(state) {
	  state.results = [];
	},
  },
  extraReducers: (builder) => {
	builder
	  .addCase(getSearch.pending, (state) => {
		state.getSearchInProgress = true;
		state.getSearchError = null;
	  })
	  .addCase(getSearch.fulfilled, (state, action) => {
		state.results = action.payload;
		state.getSearchInProgress = false;
		state.getSearchError = null;
	  })
	  .addCase(getSearch.rejected, (state, action) => {
		state.getSearchInProgress = false;
		state.getSearchError = action.payload;
	  });
  },
});

export const { clearData } = searchSlice.actions;

// this is for configureStore
export default searchSlice.reducer;
