// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../shared/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../shared/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@custom-media --viewportSmall (min-width: 550px);
@custom-media --viewportMedium (min-width: 768px);
@custom-media --viewportLarge (min-width: 1024px);
@custom-media --viewportLargeWithPaddings (min-width: 1128px);
@custom-media --viewportXLarge (min-width: 1921px);
`, "",{"version":3,"sources":["webpack://./src/styles/customMediaQueries.css"],"names":[],"mappings":"AAAA,gDAAgD;AAChD,iDAAiD;AACjD,iDAAiD;AACjD,6DAA6D;AAC7D,kDAAkD","sourcesContent":["@custom-media --viewportSmall (min-width: 550px);\n@custom-media --viewportMedium (min-width: 768px);\n@custom-media --viewportLarge (min-width: 1024px);\n@custom-media --viewportLargeWithPaddings (min-width: 1128px);\n@custom-media --viewportXLarge (min-width: 1921px);\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
