import React, { useState } from "react";
import { Layout, Flex, Row } from "antd";
import classNames from "classnames";
import { Link, useLocation } from "react-router-dom";
import { Breadcrumbs } from "../Breadcrumbs/Breadcrumbs";
import { MainLogo } from "../MainLogo/MainLogo";
import { MainMenu } from "../MainMenu/MainMenu";
import { IconArrow } from "../IconArrow/IconArrow";
import { Footer } from "../Footer/Footer";
import { IconLogout } from "../IconLogout/IconLogout";
import { checkUrl } from "../../utils/helpers";
import { useAuth } from "../../store/AuthContext";

import css from "./MainLayout.module.css";

const { Header, Sider, Content } = Layout;

const headerStyle = {
  textAlign: "center",
  color: "#171717",
  height: 63,
  lineHeight: "63px",
  backgroundColor: "#000000",
  borderBottom: "1px solid #73737333",
};

const contentStyle = {
  textAlign: "center",
  minHeight: 120,
  lineHeight: "120px",
  color: "#171717",
  backgroundColor: "#ffffff",
  overflow: "scroll",
  maxHeight: "calc(100vh - 120px)",
};

const siderStyle = {
  textAlign: "center",
  color: "#171717",
  backgroundColor: "#ffffff",
  minHeight: "100vh",
  position: "relative",
  borderRight: "1px solid #E3E3E3",
  boxShadow: "4px 2px 5px -5px rgba(0,0,0,0.75)",
  left: 0,
  top: 0,
  bottom: 0,
};

export const MainLayout = ({ children }) => {
  const { user, logout } = useAuth(); 
  const [collapsed, setCollapsed] = useState(false);
  const [breakPoint, setBreakPoint] = useState({
    collapsedWidth: 110,
    width: 320,
  });
  const location = useLocation();
  const { pathname } = location;

  const layoutStyle = {
    overflow: "hidden",
    width: "100%",
    maxWidth: "100%",
  };

  

  return (
    <Flex gap="middle" wrap="wrap">
      <Layout style={layoutStyle}>
        <Sider
          width={breakPoint?.width}
          collapsedWidth={breakPoint?.collapsedWidth}
          style={siderStyle}
          collapsed={collapsed}
          breakpoint="md"
          trigger={null}
          onBreakpoint={(broken) => {
            if (broken) {
              setBreakPoint({ collapsedWidth: 0, width: "100%" });
            } else {
              setBreakPoint({ collapsedWidth: 110, width: 320 });
            }
          }}
        >
          <Row className={css.logoContainer}>
            <MainLogo collapsed={collapsed} />
          </Row>

          <div
            className={classNames(css.collapsBtn, {
              [css.right]: collapsed,
              [css.left]: !collapsed,
            })}
            onClick={() => setCollapsed((prevState) => !prevState)}
          >
            <IconArrow direction={collapsed ? "right" : "left"} />
          </div>

          <div>
            <MainMenu />
          </div>
        </Sider>
        <Layout>
          <Header style={headerStyle}>
            {user && <Flex
              align="center"
              gap={10}
              justify="flex-end"
              style={{ height: "100%", cursor: "pointer" }}
              onClick={logout}
            >
              <span className={css.logoutText}>{user.first_name} {user.last_name} </span>
              <IconLogout className={css.logoutIcon} />
            </Flex>}
          </Header>
          <Breadcrumbs />
          <Content style={contentStyle}>{children}</Content>
          <Footer />
        </Layout>
      </Layout>
    </Flex>
  );
};
