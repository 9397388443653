// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../shared/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../shared/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PageHeader_headerContainer__e\\+9-c {
    display: flex;
    align-self: flex-start;
    flex-direction: column;
    text-align: left;
    width: 100%;
}

.PageHeader_title__YDhmh {
    font-size: 20px;
    line-height: 20px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.PageHeader_headerActions__qJ1u7 {
    
}`, "",{"version":3,"sources":["webpack://./src/components/PageHeader/PageHeader.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,sBAAsB;IACtB,gBAAgB;IAChB,WAAW;AACf;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,8BAA8B;AAClC;;AAEA;;AAEA","sourcesContent":[".headerContainer {\n    display: flex;\n    align-self: flex-start;\n    flex-direction: column;\n    text-align: left;\n    width: 100%;\n}\n\n.title {\n    font-size: 20px;\n    line-height: 20px;\n    width: 100%;\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n}\n\n.headerActions {\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerContainer": `PageHeader_headerContainer__e+9-c`,
	"title": `PageHeader_title__YDhmh`,
	"headerActions": `PageHeader_headerActions__qJ1u7`
};
export default ___CSS_LOADER_EXPORT___;
