export const IconArrow = ({ direction }) => {
  return (
    <>
      {direction === "left" ? (
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.31416 6.3L8.31416 0.3C8.71416 -0.0999998 9.31416 -0.0999998 9.71416 0.3C10.1142 0.7 10.1142 1.3 9.71416 1.7L4.41416 7L9.71416 12.3C10.1142 12.7 10.1142 13.3 9.71416 13.7C9.51416 13.9 9.31416 14 9.01416 14C8.71416 14 8.51416 13.9 8.31416 13.7L2.31416 7.7C1.91416 7.3 1.91416 6.7 2.31416 6.3Z"
            fill="white"
          />
        </svg>
      ) : direction === "right" ? (
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.6858 6.3L5.68584 0.3C5.28584 -0.0999998 4.68584 -0.0999998 4.28584 0.3C3.88584 0.7 3.88584 1.3 4.28584 1.7L9.58584 7L4.28584 12.3C3.88584 12.7 3.88584 13.3 4.28584 13.7C4.48584 13.9 4.68584 14 4.98584 14C5.28584 14 5.48584 13.9 5.68584 13.7L11.6858 7.7C12.0858 7.3 12.0858 6.7 11.6858 6.3Z"
            fill="white"
          />
        </svg>
      ) : null}
    </>
  );
};
