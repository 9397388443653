// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../shared/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../shared/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MainButton_primaryButton__5H4D\\+ {
    background-color: var(--colorRedLight);
    box-shadow: none;

    font-weight: 600;
    font-size: 12px;
    min-height: 47px;

    &:hover {
        background-color: var(--colorRedDark) !important;
    }
}

.MainButton_primaryButtonDark__BuTKa {
    background-color: #171717;
    box-shadow: none;

    font-weight: 600;
    font-size: 12px;
    min-height: 47px;

    &:hover {
        background-color: #4C4C4C !important;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/MainButton/MainButton.module.css"],"names":[],"mappings":"AAAA;IACI,sCAAsC;IACtC,gBAAgB;;IAEhB,gBAAgB;IAChB,eAAe;IACf,gBAAgB;;IAEhB;QACI,gDAAgD;IACpD;AACJ;;AAEA;IACI,yBAAyB;IACzB,gBAAgB;;IAEhB,gBAAgB;IAChB,eAAe;IACf,gBAAgB;;IAEhB;QACI,oCAAoC;IACxC;AACJ","sourcesContent":[".primaryButton {\n    background-color: var(--colorRedLight);\n    box-shadow: none;\n\n    font-weight: 600;\n    font-size: 12px;\n    min-height: 47px;\n\n    &:hover {\n        background-color: var(--colorRedDark) !important;\n    }\n}\n\n.primaryButtonDark {\n    background-color: #171717;\n    box-shadow: none;\n\n    font-weight: 600;\n    font-size: 12px;\n    min-height: 47px;\n\n    &:hover {\n        background-color: #4C4C4C !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primaryButton": `MainButton_primaryButton__5H4D+`,
	"primaryButtonDark": `MainButton_primaryButtonDark__BuTKa`
};
export default ___CSS_LOADER_EXPORT___;
