import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import routes from "./routes";
import { MainLayout } from "./components/MainLayout/MainLayout";
import { AuthProvider } from "./store/AuthContext";

import "./App.css";
const App = () => {
  return (
    <Router>
      <AuthProvider> 
        <MainLayout>
          <Routes>
            {routes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                element={route.element}
              />
            ))}
          <Route exact path="/" element={<Navigate replace to="/dashboard" />} />
          </Routes>
        </MainLayout>
      </AuthProvider>
    </Router>
  );
};

export default App;
