import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Col, Flex, Form, Input, Row, Button } from "antd";
import { PageHeader } from "../../components/PageHeader/PageHeader";
import { getSearch } from "./searchSlice";
import { buildQueryString, debounce } from "../../utils/helpers";
import { IconSearch } from "../../components/IconSearch/IconSearch";
import { SearchSelect } from "../../components/SearchSelect/SearchSelect";

import css from "../Dashboard/Dashboard.module.css";

export const SearchBox = ({onApply}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [value, setValue] = React.useState([]);
  
  const getFormattedSearch = async(query) => {
	  return dispatch(
		  getSearch({
			query,
		  })
	  ).then(values=>{
		return Array.isArray(values.payload) ? values.payload.map(result=>({
			label: `${result.name} (${result.entity})`,
			value: result.name,
			entity: result.entity
		})) : [];  
	  })
  }

  return (
	<Flex className={css.root} vertical>
	  <Form
		name="searchForm"
		style={{ marginTop: 10, marginBottom: 0 }}
		initialValues={{ remember: true }}
		autoComplete="off"
	  >
		<Row gutter={16} className={css.searchFormContainer}>
		  <Col md={24} lg={20} xl={20}>
			<Form.Item style={{ marginBottom: 10 }} name="search">
				<SearchSelect
			  	mode="multiple"
			  	value={value}
			  	placeholder="Select labels, artists, product name, code and UPC"
			  	fetchOptions={getFormattedSearch}
			  	onChange={(newValue) => {
					setValue(newValue);
			  	}}
			  	style={{
					width: '100%',
			  	}}
				/>
			</Form.Item>
		  </Col>
		  <Col md={24} lg={4} xl={4} className={css.actionsWrapper}>
		  <Button type="primary" htmlType="submit" onClick={()=>onApply(value)}>
				  Apply
			</Button>

		  </Col>
		</Row>
	  </Form>
	</Flex>
  );
};
