export const IconLogout = ({ className }) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ minHeight: 24, minWidth: 24 }}
    >
      <g clip-path="url(#clip0_19_538)">
        <path
          d="M8.00098 22L3.00099 22C2.73577 22 2.48141 21.8946 2.29388 21.7071C2.10634 21.5196 2.00099 21.2652 2.00099 21L2.00099 3C2.00099 2.73478 2.10634 2.48043 2.29388 2.29289C2.48142 2.10536 2.73577 2 3.00099 2L8.00098 2L8.00098 0L3.00099 -2.18557e-07C2.20534 -2.53335e-07 1.44228 0.31607 0.879669 0.878679C0.317061 1.44129 0.000991725 2.20435 0.00099169 3L0.000990903 21C0.000990869 21.7956 0.31706 22.5587 0.879668 23.1213C1.44228 23.6839 2.20534 24 3.00099 24L8.00098 24L8.00098 22Z"
          fill="#171717"
        />
        <path
          d="M24.0005 11.967C24.0016 11.5732 23.9249 11.183 23.7749 10.8189C23.625 10.4547 23.4046 10.1238 23.1265 9.84496L19.2075 5.92596L17.7935 7.33996L21.4265 10.974L5.00048 11L5.00048 13L21.4125 12.974L17.7935 16.593L19.2075 18.007L23.1265 14.088C23.4046 13.8093 23.6249 13.4786 23.7749 13.1146C23.9249 12.7506 24.0015 12.3606 24.0005 11.967Z"
          fill="#171717"
        />
      </g>
      <defs>
        <clipPath id="clip0_19_538">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(24) rotate(90)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
