import React from "react";
import { Col, Flex, Layout, Row } from "antd";
import css from "./Footer.module.css";
const { Footer: AntFooter } = Layout;

export const Footer = () => {
  return (
    <AntFooter className={css.footer}>
      <Flex vertical={false} justify="space-between">
        <Row className={css.rowLeft}>
          <Col span={24}>© 2024 Cargo Records. All right reserved</Col>
        </Row>
        <Row gutter={16} className={css.rowRight}>
          <Col className={css.col}>TERMS</Col>
          <Col className={css.col}>PRIVACY</Col>
          <Col className={css.col}>DO NOT SELL MY PERSONAL INFORMATION</Col>
        </Row>
      </Flex>
    </AntFooter>
  );
};
