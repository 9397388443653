import React from "react";
import css from "./PageHeader.module.css";

export const PageHeader = ({ title, subtitle, children = null }) => {
  return (
    <div className={css.headerContainer}>
      <h1 className={css.title}>{title}<span className={css.headerActions}>{children}</span></h1>
      <h2>{subtitle}</h2>
    </div>
  );
};
