// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../shared/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../shared/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MainMenu_mainmenu__kIS7S {
	width: 256;
}`, "",{"version":3,"sources":["webpack://./src/components/MainMenu/MainMenu.module.css"],"names":[],"mappings":"AAAA;CACC,UAAU;AACX","sourcesContent":[".mainmenu {\n\twidth: 256;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainmenu": `MainMenu_mainmenu__kIS7S`
};
export default ___CSS_LOADER_EXPORT___;
